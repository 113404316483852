body {
    font-size: 13px;
    font-family: arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
.clean {
    clear: both;
}

p {
    text-align: justify;
}

a,
a:visited {
    color: #00f;
}

.nod,
.nop,
.header,
.menu,
.footer,
.breadcrumbs__container,
.sidebar,
.header-banner,
.product__contact-box,
hr {
    display: none;
}

.main form table th,
.main form table td {
    border: 0;
}

.main table {
    border-collapse: collapse;
}

.main th {
    padding: 3px 5px;
    border: 0;
}

img.fl {
    margin: 0 10px 10px 0;
}

img.fr {
    margin: 0 0 10px 10px;
}

.img img {
    float: left !important;
    margin: 0 10px 10px 0;
    border: 0;
}

.picture {
    float: right !important;
    margin: 0 0 0 15px;
}

.picturel {
    float: left !important;
    margin: 0 15px 0 0;
}

a img {
    border: 0;
}

.fl {
    float: left !important;
}

.fr {
    float: right !important;
}

.center {
    text-align: center;
}

.right {
    text-align: center;
}

.vtop {
    vertical-align: top;
}